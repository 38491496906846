import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Dropzone from 'react-dropzone';

import { ACCEPTED_TYPES } from '@core/models/Attachment';
import { parseDocx } from '@core/parsing/DOCX';

import { Button } from '@components/dmp';
import { Icon } from '@components/dmp';

const ERRORS = {
  UNKNOWN: 'We were unable to process your document, please contact us for help.',
  JSON: 'This JSON file is not a valid Outlaw Deal format.',
};

@autoBindMethods
export default class FileUp extends Component {
  static propTypes = {
    deal: PropTypes.object,
    onParse: PropTypes.func,
    file: PropTypes.instanceOf(File),
    mergeType: PropTypes.oneOf(['overwrite', 'merge', 'version', 'redline']).isRequired,
    acceptedTypes: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      arrayBuffer: null,
      error: null,
      file: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.file) {
      this.parse(this.props.file);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  get documentFormatName() {
    const { acceptedTypes } = this.props;
    const { file } = this.state;

    let name = 'Unsupported';

    const extension = file.name.split('.').pop().toLowerCase();

    if (file) {
      const supportedType = _.find(acceptedTypes, { extension });
      if (supportedType) {
        name = supportedType.display.toUpperCase();
      }
    }

    return name;
  }

  get acceptedTypesDisplay() {
    const { acceptedTypes } = this.props;
    return _.map(acceptedTypes, (t) => `.${t.display}`).join(', ');
  }

  get icon() {
    const { file } = this.state;

    if (!file) return <Icon name="upload" />;

    const fileType = _.find(ACCEPTED_TYPES, { extension: file.name.split('.').pop() });
    const iconName = _.get(fileType, 'icon', 'upload');

    return <Icon name={iconName} />;
  }

  // http://stackoverflow.com/questions/17710147/image-convert-to-base64
  parse(file) {
    const reader = new FileReader();
    const onFile = (arrayBuffer) => {
      if (!this._isMounted) return;
      this.setState({ arrayBuffer, file }, async () => {
        if (!arrayBuffer) return;

        const { deal, mergeType, onParse } = this.props;
        let parsed;

        const extension = file.name.split('.').pop().toLowerCase();

        switch (extension) {
          // Since parseDocx is still in Beta and not stable enough, let's catch any errors and
          // inform the user if the parsing failed.
          case ACCEPTED_TYPES.WORD.extension:
            try {
              parsed = await parseDocx(arrayBuffer, deal, mergeType);
              parsed.fileType = file.type;
              this.setState(parsed);
              onParse(parsed, file, arrayBuffer);
            } catch (err) {
              console.error(err);
              this.setState({ error: ERRORS.UNKNOWN });
              throw new Error(err);
            }
            break;
          case ACCEPTED_TYPES.JSON.extension:
          case ACCEPTED_TYPES.VINE.extension:
            try {
              // Normally we'd use readAsText() but we have an array buffer for the other upload methods
              // So we need to decode it manually and then parse to JSON
              // https://stackoverflow.com/questions/6965107/converting-between-strings-and-arraybuffers
              const dec = new TextDecoder('utf-8');
              const jsonString = dec.decode(arrayBuffer);
              const json = JSON.parse(jsonString);

              parsed = {
                title: _.get(json, 'info.name', ''),
                fileType: ACCEPTED_TYPES.JSON.mime,
                json,
              };

              this.setState({ parsed });
              onParse(parsed, file, arrayBuffer);
              break;
            } catch (err) {
              this.setState({ error: ERRORS.JSON });
              throw new Error(err);
            }
          // For all other types we don't actually do any parsing on client-side upload,
          // just snag a title from filename
          default:
            parsed = {
              title: file.name.split('.')[0].replace(/[-_]/g, ' '),
              fileType: file.type,
            };
            this.setState({ parsed });
            onParse(parsed, file, arrayBuffer);
            break;
        }
      });
    };

    reader.onload = (e) => onFile(e.target.result);
    reader.onerror = () => onFile(null);

    reader.readAsArrayBuffer(file);
  }

  onDropAccepted(files) {
    if (files.length > 0) {
      this.setState({ error: null });
      this.parse(files[0]);
    }
  }

  onDropRejected(files) {
    const type = files[0].type.split('/')[1];
    const error = `Must be a supported format: ${this.acceptedTypesDisplay}. The file type you entered was of type: ${type}.`;
    this.removeFile();
    this.setState({ error });
  }

  removeFile(e) {
    if (e) e.stopPropagation();
    this.setState({ error: null, arrayBuffer: null, file: null });
    this.props.onParse(null, null, null);
  }

  render() {
    const { arrayBuffer, error, file } = this.state;
    const acceptedTypes = _.map(this.props.acceptedTypes, (t) => `.${t.extension}`);

    return (
      <Dropzone
        className="file-up"
        activeClassName="uploader active"
        accept={acceptedTypes}
        disableClick={arrayBuffer != null}
        multiple={false}
        onDropAccepted={this.onDropAccepted}
        onDropRejected={this.onDropRejected}
        data-cy="file-up"
      >
        {arrayBuffer && (
          <div className="hit-area">
            {this.icon}
            <div className="instructions">
              <h4>{file.name}</h4>
              <small>{this.documentFormatName} Document Format</small>
              {error && <small className="error">{error}</small>}
            </div>
            <Button className="clear" onClick={this.removeFile}>
              ×
            </Button>
          </div>
        )}

        {!arrayBuffer && (
          <div className="hit-area">
            {this.icon}
            <div className="instructions">
              <h4>Choose a file or drag and drop</h4>
              <small>Accepted: {this.acceptedTypesDisplay}.</small>
              {error && <small className="error">{error}</small>}
            </div>
          </div>
        )}
      </Dropzone>
    );
  }
}
