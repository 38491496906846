import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { LETTERHEAD_OPTIONS } from '@core/models/Branding';
import Deal, { PDF_CHAR_SETS, PDF_CHAR_SETS_DEFAULT } from '@core/models/Deal';
import Team from '@core/models/Team';
import { dt } from '@core/utils';

import { Dropdown, MenuItem, Switch } from '@components/dmp';

import FootnoteConfig from '@components/FootnoteConfig';
import WatermarkForm from '@components/WatermarkForm';
import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import ThemeSelector from '@components/teams/ThemeSelector';
import Fire from '@root/Fire';

@autoBindMethods
export default class PageSidebar extends Component {
  static propTypes = {
    deal: PropTypes.instanceOf(Deal).isRequired,
    team: PropTypes.instanceOf(Team).isRequired,
  };

  constructor(props) {
    super(props);
    const { template } = props.deal;

    this.state = {
      pdfCharSet: template.pdfCharSet,
      showTitle: template.showTitle,
      showLetterhead: template.showLetterhead,
      letterheadType: template.letterheadType,
      watermark: template.watermark,
      footnoteConfig: template.footnoteConfig,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  async updateSetting(property, value) {
    const { template } = this.props.deal;
    if (template[property] !== value) {
      await this.setState({ [property]: value });
      Fire.saveTemplate(template.dealID, { [property]: value });
    }
  }

  render() {
    const { team, deal } = this.props;
    const { footnoteConfig, pdfCharSet, showTitle, showLetterhead, letterheadType, watermark } = this.state;
    const currentPdfCharSet = pdfCharSet || PDF_CHAR_SETS_DEFAULT;
    const pdfCharSetTitle = _.get(PDF_CHAR_SETS[currentPdfCharSet], 'displayName');
    const currentCharSetKey = _.get(PDF_CHAR_SETS[currentPdfCharSet], 'key');
    const letterheadTitle = letterheadType
      ? _.find(LETTERHEAD_OPTIONS, (option) => {
          return option.key === letterheadType;
        }).value
      : LETTERHEAD_OPTIONS[0].value;

    return (
      <div className="page-sidebar panel-scroll" data-cy="page-sidebar">
        <DealPanelItem borderBottom data-cy="formatting">
          <div className="property-title">Formatting</div>
          <div className="letterhead-behavior" data-cy="show-letterhead">
            <Switch
              id="chk-letterhead"
              checked={showLetterhead}
              onChange={() => this.updateSetting('showLetterhead', !showLetterhead)}
              className="item-label"
              size="small"
            >
              Show letterhead
            </Switch>
            <small>Show team logo and address on header</small>
            {showLetterhead && (
              <Dropdown
                id="dd-show-letterhead"
                onSelect={(option) => this.updateSetting('letterheadType', option)}
                title={letterheadTitle}
                pullRight
                block
                size="small"
                className="letterhead-selector"
              >
                {_.map(LETTERHEAD_OPTIONS, (option, idx) => (
                  <MenuItem eventKey={option.key} key={idx}>
                    {option.value}
                  </MenuItem>
                ))}
              </Dropdown>
            )}
          </div>
          <div className="letterhead-behavior" data-cy="show-title">
            <Switch
              id="chk-show-title"
              checked={showTitle}
              onChange={() => this.updateSetting('showTitle', !showTitle)}
              className="item-label"
              size="small"
            >
              Show title
            </Switch>
            <small>Use Template title as first section in {dt}</small>
          </div>
          <div className="item-label">Theme</div>
          <ThemeSelector
            id="dd-template-theme"
            onSelect={(theme) => this.updateSetting('theme', theme)}
            themeKey={deal.template.theme}
            team={team}
            block
            size="small"
          />
        </DealPanelItem>

        <DealPanelItem data-cy="pdf-character-set">
          <div className="property-title">PDF Output</div>

          <label className="property-subtitle">Character Set</label>
          <Dropdown
            id="dd-pdf-char-set"
            title={pdfCharSetTitle}
            onSelect={(set) => this.updateSetting('pdfCharSet', set)}
            size="small"
            block
          >
            {_.map(PDF_CHAR_SETS, (set, idx) => (
              <MenuItem key={idx} eventKey={set.key} active={set.key === currentCharSetKey}>
                {set.displayName}
              </MenuItem>
            ))}
          </Dropdown>
        </DealPanelItem>

        <DealPanelItem data-cy="watermark-set">
          <WatermarkForm watermark={watermark} updateSetting={this.updateSetting} />
        </DealPanelItem>
        <DealPanelItem borderBottom data-cy="footnote-config">
          <FootnoteConfig footnoteConfig={footnoteConfig} updateSetting={this.updateSetting} />
        </DealPanelItem>
      </div>
    );
  }
}
