import WLLoginView from '@root/routes/WLLoginView';
import AutoDeal from '@routes/AutoDeal';
import Dashboard from '@routes/Dashboard';
import DealView from '@routes/DealView';
import InviteView from '@routes/InviteView';
import LoginOkta from '@routes/LoginOkta';
import LoginView from '@routes/LoginView';
import MergeAccounts from '@routes/MergeAccounts';
import PublicDeal from '@routes/PublicDeal';
import TeamInviteView from '@routes/TeamInviteView';
import UserAuth from '@routes/UserAuth';
import Profile from '@routes/account/Profile';
import Admin from '@routes/admin/Admin';

//import AuthTools from '@routes/AuthTools';

export default {
  // app routes
  '/admin/:tab?': { component: Admin },
  '/account/:settings': { component: Profile },
  '/dashboard/:page?/:tab?': { component: Dashboard },
  '/deals/:dealID/:mode?/:sectionID?': {
    component: DealView,
    stay: true,
    anon: true,
    topBarMode: 'dealview',
  },
  '/public-deal/:dealID/:mode?': { component: PublicDeal, anon: true, stay: true, frameless: true },
  '/templates/:dealID/:mode?': { component: DealView, topBarMode: 'dealview' },
  '/mergeAccounts': { component: MergeAccounts, frameless: true, anon: true, stay: true },

  // landing page for deal invitations
  '/invitation/:inviteID/:duKey': { component: InviteView, anon: true, stay: true, frameless: true },

  // landing page for team invitations
  '/join/:teamID/:inviteID': { component: TeamInviteView, anon: true, stay: true, frameless: true },

  // iframe/teams integration
  '/in/:teamID/:templateKey/:mode?': { component: AutoDeal, anon: true, stay: true, frameless: true },

  // for email verification
  '/auth': { component: UserAuth, anon: true, stay: true, frameless: true },

  // logged-out routes
  '/': { component: LoginView, anon: true, stay: true, frameless: true },
  '/okta/:teamID?': { component: LoginOkta, anon: true, stay: true, frameless: true },
  //'/authTools': { component: AuthTools, anon: true, stay: true, frameless: true },
  '/(login|signup|forgot|link|openID)/:redirect?': {
    component: LoginView,
    anon: true,
    stay: true,
    frameless: true,
  },
  '/(wllogin|wlsignup)/:redirect?': {
    component: WLLoginView,
    anon: true,
    stay: true,
    frameless: true,
  },
};
