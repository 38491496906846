/* eslint-disable no-case-declarations */
import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import { PDFDocument } from 'pdf-lib';
import PropTypes from 'prop-types';

import { Alert, ControlLabel, FormControl, FormGroup, Modal, ProgressBar } from 'react-bootstrap';

import DealStatus from '@core/enums/DealStatus';
import { ACCEPTED_TYPES, NEW_CONTRACT_TYPES } from '@core/models/Attachment';
import { DEAL_TYPE } from '@core/models/Deal';
import Template from '@core/models/Template';
import { MERGE_TYPE } from '@core/models/Version';
import Workflow, { DEFAULT_WORKFLOW, DEFAULT_WORKFLOW_STATIC } from '@core/models/Workflow';
import { Dt, arrayToBase64, dt } from '@core/utils';

import { Button } from '@components/dmp';

import DealStatusSelector from '@components/deal/DealStatusSelector';
import FileUp from '@components/editor/FileUp';
import TeamSelector from '@components/teams/TeamSelector';
import TemplateSelector from '@components/teams/TemplateSelector';
import API from '@root/ApiClient';
import Fire from '@root/Fire';

const PDF_MODES = [
  {
    key: 'signNow',
    title: 'eSign it now',
    description: 'Add basic text fields, name and signature',
    route: 'contract',
    selectTemplate: true,
    selectStatus: true,
  },

  {
    key: 'storage',
    title: 'Storage only',
    description: `Upload a previously executed ${dt}`,
    route: 'contract',
    selectTemplate: true,
    selectStatus: true,
  },
];

const WORD_MODES = [
  {
    key: 'signNow',
    title: 'eSign it now',
    description: 'Convert to PDF to add basic text fields, name and signature',
    route: 'contract',
    selectTemplate: true,
    selectStatus: false,
    dealStatus: DealStatus.REVIEW.data,
  },
];

@autoBindMethods
export default class ContractUploader extends Component {
  static defaultProps = {
    deal: null,
    onClose: _.noop,
  };

  static propTypes = {
    show: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    team: PropTypes.object,
    user: PropTypes.object,
    teams: PropTypes.array.isRequired,
    selectTeam: PropTypes.func.isRequired,
    // This is passed in via props in the drag-drop scenario from DealsPage
    droppedFile: PropTypes.instanceOf(File),
  };

  constructor(props) {
    super(props);

    const workflow = new Workflow(DEFAULT_WORKFLOW);

    this.state = {
      saving: false,
      error: null,
      errorInfo: null,
      progress: null,
      parsed: null,
      file: null,
      title: '',
      workflow,
      currentStep: workflow.steps[0],
      // List of available templates (for current team) and currently selected one
      templates: [],
      template: null,
      pdfMode: 'signNow',
      wordMode: 'signNow',
      rawTemplate: null,
      uploadTeam: props.teams.length === 1 ? props.teams[0] : null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { uploadTeam } = this.state;
    if (uploadTeam) this.getTemplates(uploadTeam.teamID);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  get ready() {
    const { parsed, title, uploadTeam } = this.state;
    return parsed && title && uploadTeam;
  }

  get isWord() {
    return _.get(this.state.file, 'type') === ACCEPTED_TYPES.WORD.mime;
  }

  get creationMode() {
    if (this.isWord) {
      return _.find(WORD_MODES, { key: this.state.wordMode });
    } else {
      return _.find(PDF_MODES, { key: this.state.pdfMode });
    }
  }

  get accessibleTeams() {
    return this.props.teams.filter((team) => this.props.user.canCreateNewDoc(team));
  }

  onParse(parsed, file, arrayBuffer) {
    this.setState({
      parsed,
      file,
      arrayBuffer,
      title: _.get(parsed, 'title') || '',
    });
  }

  handleEnter(e) {
    if (e.key === 'Enter' && this.ready) {
      this.tryUpload();
    }
  }

  async getTemplates(teamID) {
    API.call('getTeamTemplates', { teamID, activeOnly: true }, async (raw) => {
      const templates = raw.map((template) => new Template(template, template.dealID));
      if (this._isMounted) {
        await this.setState({ templates });
        // After loading team's templates, ensure that we deselect anything currently selected
        // this will auto-select the team's default workflow, if one is set
        await this.selectTemplate(null);
      }
    });
  }

  async selectTemplate(template) {
    const { uploadTeam } = this.state;
    // When a template is selected, we need to load the actual Deal object,
    // so that its correct workflow loads (see Fire.getWorkflow, which is called internally by Fire.getDeal).
    // Then we can make a temporary (disconnected) copy of the workflow
    // to present the correct steps to user for initial status selection
    let workflow;
    let rawTemplate;
    if (template) {
      rawTemplate = await Fire.getDeal(template.dealID);
      //when uploading PDFs, the deals uploaded using a template without signature blocks, which
      //tradionally have DEFAULT_WORKFLOW_STATIC workflow (unless custom is defined) assigned to them,
      //should be overwritten to DEFAULT_WORKFLOW
      //check Fire.js -> getWorkflow() to see how workflows are assigned when we getDeal()
      if (rawTemplate.workflow.name === DEFAULT_WORKFLOW_STATIC.name && rawTemplate.workflow.isOutlaw === true) {
        workflow = new Workflow(DEFAULT_WORKFLOW);
      } else {
        workflow = new Workflow(rawTemplate.workflow.json);
      }
    }
    // If no template selected, use default
    else {
      const defaultTeamWF = _.find(uploadTeam.workflows, { isDefault: true });
      if (defaultTeamWF) {
        workflow = new Workflow(defaultTeamWF.json);
      }
      // If no custom team workflow is set, fallback to Outlaw
      else {
        workflow = new Workflow(DEFAULT_WORKFLOW);
      }
    }

    this.setState({
      template,
      workflow,
      currentStep: workflow.steps[0],
      rawTemplate,
    });
  }

  async tryUpload() {
    const { arrayBuffer, file } = this.state;

    // PDF
    if (file && file.type === ACCEPTED_TYPES.PDF.mime) {
      // We are currently not supporting encrypted PDFs
      // Attempt to open it for editing, if it's encrypted, it'll fail and we'll tell
      // the user to decrypt it (until we support PDF decryption)
      try {
        const pdfBytes = new Uint8Array(arrayBuffer);
        await PDFDocument.load(pdfBytes);
      } catch (err) {
        this.setState({
          error: 'Encrypted PDF files are not supported on Outlaw.',
          errorInfo: (
            <div>
              To upload this file, remove its encryption first.{' '}
              <a
                href="https://filevine.zendesk.com/knowledge/articles/17344599317531/en-us?brand_id=4415356259995"
                target="_blank"
                rel="noreferrer"
              >
                Learn how
              </a>
            </div>
          ),
        });
        return;
      }
    }

    try {
      await this.upload();
    } catch (error) {
      this.setState({ error: _.get(error, 'message', 'An error occurred'), errorInfo: null });
    }
  }

  async upload() {
    const { user, history } = this.props;
    const { parsed, title, currentStep, saving, template, arrayBuffer, rawTemplate, uploadTeam } = this.state;

    // Avoid duplicate import/upload attempts; ensure we have all data/selections necessary to proceed!
    if (saving || !parsed || !title || !uploadTeam) return;

    const dealStatus = this.creationMode.dealStatus || currentStep.key;

    let successPath, dealType;

    switch (parsed.fileType) {
      case ACCEPTED_TYPES.PDF.mime:
        successPath = this.creationMode.route;
        dealType = DEAL_TYPE.EXTERNAL;
        break;

      case ACCEPTED_TYPES.WORD.mime:
        successPath = 'contract';
        dealType = DEAL_TYPE.EXTERNAL_WORD;
        break;

      case ACCEPTED_TYPES.JSON.mime:
        successPath = '';
        break;
      default:
        this.setState({ error: 'Invalid file type.', errorInfo: null });
        return;
    }

    const base64data = arrayToBase64(new Uint8Array(arrayBuffer));

    const createDeal = {
      userID: user.id,
      fileType: parsed.fileType,
      title,
      dealStatus,
      dealType,
      teamID: uploadTeam.teamID,
      data: base64data,
    };

    if (parsed.json) {
      // Handle .vine upload
      createDeal.importJSON = parsed.json;
    }

    if (template) {
      createDeal.templateDealID = template.dealID;
    }

    this.setState({ saving: true, progress: { current: 1, total: 1, description: 'Processing Document' } });

    const result = await API.call('createDealFromDocument', createDeal);

    if (result.success) {
      this.onClose();
      history.push(`/deals/${result.dealID}/${successPath}`);
    } else {
      this.setState({ error: _.get(result.error, 'message', 'An error occurred'), errorInfo: null });
    }
  }

  reset() {
    if (this._isMounted) {
      this.setState({
        saving: false,
        error: null,
        errorInfo: null,
        progress: null,
        parsed: null,
        file: null,
        title: '',
      });
    }
  }

  onClose() {
    if (this.canClose) {
      this.reset();
      this.props.onClose();
    }
  }

  selectTeam(teamID) {
    const team = _.find(this.props.teams, { teamID });
    this.setState({ uploadTeam: team, template: null });
  }

  clearTeam() {
    this.setState({ uploadTeam: null, template: null });
  }

  renderSaving() {
    const progress = _.get(this.state, 'progress', { current: 0, total: 1, description: '' });
    const percent = (progress.current / progress.total) * 100;

    return (
      <Modal.Body>
        <div className="saving">
          <ProgressBar bsStyle="info" now={percent} />
          <div className="details">
            <div className="step">
              {progress.current} of {progress.total}
            </div>
            <div className="description">{progress.description}</div>
          </div>
        </div>
      </Modal.Body>
    );
  }

  renderError() {
    const { error, errorInfo } = this.state;

    return (
      <>
        <Modal.Body>
          <div className="saving error">
            <Alert bsStyle="danger">
              <strong>Upload failed</strong> {error}
            </Alert>
            {errorInfo}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.reset}>Try Again</Button>
        </Modal.Footer>
      </>
    );
  }

  get canClose() {
    const { saving, error } = this.state;
    return !saving || !!error;
  }

  renderBody() {
    const { droppedFile, user } = this.props;
    const { title, workflow, currentStep, template, uploadTeam } = this.state;
    const creationMode = this.creationMode;

    const accessibleTeams = this.accessibleTeams;

    return (
      <>
        <FormGroup className="team-selector-wrapper">
          <ControlLabel>Team</ControlLabel>
          <TeamSelector
            user={user}
            teamID={uploadTeam?.teamID || null}
            teams={accessibleTeams}
            onSelect={this.selectTeam}
            disableNew
            disableOption={(team) => !user.canCreateNewDoc(team)}
            placeholder={'Select...'}
            disabled={uploadTeam && accessibleTeams.length === 1}
            onClear={this.clearTeam}
          />
        </FormGroup>

        <Modal.Body>
          <div className="wrapper">
            <FormGroup>
              <ControlLabel>Upload</ControlLabel>
              <div className="contents">
                <FileUp
                  file={droppedFile}
                  acceptedTypes={NEW_CONTRACT_TYPES(user)}
                  mergeType={MERGE_TYPE.OVERWRITE}
                  onParse={this.onParse}
                />
              </div>
            </FormGroup>
          </div>

          <div className="wrapper">
            <FormGroup>
              <ControlLabel>{Dt} Title</ControlLabel>
              <div className="contents deal-title" data-cy="deal-title">
                <FormControl
                  type="text"
                  value={title}
                  placeholder={`e.g., Vendor ${Dt}`}
                  onChange={(e) => this.setState({ title: e.target.value })}
                  onKeyDown={this.handleEnter}
                />
              </div>
            </FormGroup>
          </div>

          {creationMode.selectTemplate && uploadTeam && (
            <div className="wrapper">
              <FormGroup>
                <ControlLabel>Template</ControlLabel>
                <div className="contents">
                  <TemplateSelector
                    team={uploadTeam}
                    selectedTemplateKey={template?.key}
                    onSelect={this.selectTemplate}
                  />
                  {template && (
                    <div className="explanation" data-cy="explanation">
                      Team members who are auto-included on this template will also be added to the uploaded PDF.
                    </div>
                  )}
                </div>
              </FormGroup>
            </div>
          )}

          {creationMode.selectStatus && (
            <div className="wrapper">
              <FormGroup>
                <ControlLabel>{Dt} Status</ControlLabel>
                <div className="contents deal-status">
                  <DealStatusSelector
                    steps={workflow.steps}
                    enableAllSteps
                    onSelect={(currentStep) => this.setState({ currentStep })}
                    currentStep={currentStep}
                  />
                </div>
              </FormGroup>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={!this.ready} dmpStyle="primary" onClick={this.tryUpload} data-cy="btn-upload-contract">
            Upload
          </Button>
        </Modal.Footer>
      </>
    );
  }

  render() {
    const { show } = this.props;
    const { saving, error } = this.state;

    return (
      <Modal dialogClassName="contract-uploader" show={show} onHide={this.onClose} data-cy="contract-uploader">
        <Modal.Header closeButton={this.canClose}>
          <span className="headline">{saving ? 'Uploading' : 'Upload'}</span>
        </Modal.Header>

        {error ? this.renderError() : saving ? this.renderSaving() : this.renderBody()}
      </Modal>
    );
  }
}
