import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonGroup, ControlLabel, FormGroup, Modal } from 'react-bootstrap';
import RJV from 'react-json-view';

import BorderStyle, { BORDER_EDITABLE_PROPERTIES, BORDER_LOCATIONS } from '@core/models/BorderStyle';
import { BASE_LAYOUT, REQUIRED_TYPE } from '@core/models/DealStyle';
import LayoutStyle, {
  LAYOUT_TYPES,
  LINE_NUMBER_OPTIONS,
  MARGIN_LOCATIONS,
  MARGIN_SIZES,
} from '@core/models/LayoutStyle';
import Team from '@core/models/Team';
import TypeStyle, {
  ALIGNMENT_STYLES,
  FONT_SIZES,
  INLINE_STYLES,
  LINE_SPACINGS,
  generateNative,
} from '@core/models/TypeStyle';
import { THEME_FONTS } from '@core/models/TypeStyle';
import User from '@core/models/User';
import { Default } from '@core/themes';
import { convertPointsToPixels } from '@core/utils';

import { Button, Dropdown, Form, MenuItem, Switch } from '@components/dmp';

import ColorPicker from '@components/ColorPicker';
import TooltipButton from '@components/editor/TooltipButton';
import Fire from '@root/Fire';

const TABS = [
  { key: 'general', title: 'General' },
  { key: 'page', title: 'Page' },
];

@autoBindMethods
export default class DealStyleEditor extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    team: PropTypes.instanceOf(Team).isRequired,
    user: PropTypes.instanceOf(User).isRequired,
    theme: PropTypes.object,
    onSave: PropTypes.func,
    onHide: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      selectedType: REQUIRED_TYPE[0],
      editingStyle: null,
      native: true,
      tab: TABS[0],
    };
  }

  componentDidUpdate(prevProps) {
    const { theme, show } = this.props;
    const { selectedType } = this.state;

    if (show && theme && (!prevProps.show || theme !== prevProps.theme)) {
      this.selectType(selectedType);
    }
  }

  get typeStyle() {
    const { theme } = this.props;
    const { selectedType } = this.state;
    let json = theme.dealStyle.type[selectedType.key];

    if (!json) {
      json = Default.type[selectedType.key];
    }
    const ts = new TypeStyle(json, selectedType.key);
    return ts;
  }

  getLayoutStyle(layoutProperty) {
    const { theme } = this.props;
    let json = theme.dealStyle.layout[layoutProperty];

    if (!json) {
      json = BASE_LAYOUT[layoutProperty];
    }
    return new LayoutStyle(json);
  }

  getBorderStyle(key) {
    const { theme } = this.props;
    const border = theme.dealStyle.border;
    let json = border ? border[key] : null;

    if (!json) {
      json = { key };
    }
    return new BorderStyle(json);
  }

  get numberScheme() {
    const { theme } = this.props;
    let lineNumber = theme.dealStyle.type.LineNumbering?.native;
    //for legacy without line numbering applied yet.
    lineNumber = lineNumber ? lineNumber : Default.type.LineNumbering.native;
    return _.find(LINE_NUMBER_OPTIONS, (option) => {
      return option.key === lineNumber.numberScheme;
    });
  }

  async toggleNative() {
    const { native, selectedType } = this.state;
    await this.setState({ native: !native });
    this.selectType(selectedType);
  }

  async selectType(selectedType) {
    const { native } = this.state;

    if (this.state.selectedType !== selectedType) {
      await this.setState({ selectedType });
    }

    const ts = this.typeStyle;
    let editingStyle = null;

    if (native) {
      editingStyle = {
        native: ts.isNative ? ts.native : generateNative(ts.raw),
      };
    } else {
      editingStyle = _.pick(ts, ['css', 'docx', 'pdf']);
    }

    this.setState({ editingStyle });
  }

  async saveLayoutStyle(layoutProperty, property, val) {
    const { team, theme, onSave } = this.props;

    //setup to work wiht future layout properties.
    switch (layoutProperty) {
      case LAYOUT_TYPES.PAGE_MARGIN:
        const pageMargin = this.getLayoutStyle('PageMargin');
        //convert inches back to pixles
        val = convertPointsToPixels(val, 96);
        pageMargin[property] = val;
        await Fire.saveThemeLayoutStyle(team, theme, layoutProperty, pageMargin);
        onSave();
        break;
    }
  }

  async saveBorderStyle(key, property, value) {
    const { team, theme, onSave } = this.props;

    // The starting default value shouldn't be less than the minimum(e.g. 0.4")
    const borderStyle = this.getBorderStyle(key);
    const borderObject = _.find(BORDER_EDITABLE_PROPERTIES, { key: 'layout' });
    if (borderStyle.layout.inches[borderStyle.layoutKey].toFixed(1) < borderObject.values[0].value) {
      borderStyle.layout[borderStyle.layoutKey] = (borderObject.values[0].value * 96).toFixed(0);
    }

    if (property === 'layout') {
      value = convertPointsToPixels(value, 96);
      borderStyle.layout[borderStyle.layoutKey] = value;
    } else {
      borderStyle[property] = value;
    }
    await Fire.saveThemeBorderStyle(team, theme, key, borderStyle);
    onSave();
  }

  async saveNative(prop, val) {
    const { team, theme, onSave } = this.props;
    const { editingStyle, selectedType } = this.state;

    if (!editingStyle.native) return;

    editingStyle.native[prop] = val;
    //one seperate case for the outlier prop used for line numbering.
    //we do not change the editing style because it occurs on a different tab.
    if (prop === 'numberScheme') {
      if (!theme.dealStyle.type.LineNumbering) {
        theme.dealStyle.type.LineNumbering = Default.type.LineNumbering;
      }
      theme.dealStyle.type.LineNumbering.native[prop] = val;
      await Fire.saveThemeTypeStyle(team, theme, 'LineNumbering', theme.dealStyle.type.LineNumbering);
    } else {
      await Fire.saveThemeTypeStyle(team, theme, selectedType.key, editingStyle);
      await this.setState({ editingStyle });
    }
    onSave();
  }

  //https://github.com/mac-s-g/react-json-view#onedit-onadd-and-ondelete-interaction
  async onEdit({ existing_src, existing_value, name, namespace, new_value, updated_src }) {
    const { team, theme, onSave } = this.props;
    const { selectedType } = this.state;

    await Fire.saveThemeTypeStyle(team, theme, selectedType.key, updated_src);
    await this.setState({ editingStyle: updated_src });
    onSave();
  }

  render() {
    const { onHide, show, theme, user } = this.props;
    const { editingStyle, native, tab } = this.state;

    if (!theme || !editingStyle) return null;

    return (
      <Modal backdrop="static" animation={false} dialogClassName="deal-style-editor" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <div className="theme-title">
            <span className="headline">{theme.name}</span>
          </div>
          <ButtonGroup className="panel-tabs" data-cy="panel-tabs">
            {TABS.map((t) => (
              <Button
                key={t.key}
                size="large"
                dmpStyle="link"
                active={tab.key === t.key}
                onClick={() => this.setState({ tab: t })}
              >
                {t.title}
              </Button>
            ))}
          </ButtonGroup>
        </Modal.Header>
        {tab.key === 'general' && this.renderGeneral()}
        {tab.key === 'page' && this.renderPage()}

        <Modal.Footer>
          {/*user.isAdmin && tab.key === 'general' && (
            <Switch id="chk-native-styles" onChange={this.toggleNative} checked={!native} size="small">
              Advanced mode
            </Switch>
          )*/}
          <div className="spacer" />
          <Button onClick={onHide}>Done</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderPage() {
    const pageMargin = this.getLayoutStyle('PageMargin');

    return (
      <Modal.Body>
        <div className="page-styles">
          <div className="page-style-container margins" data-cy="page-style-margins">
            <div className="page-style-title" data-cy="page-style-title">
              <span>Margins</span>
              <br />
              <span className="description">From page edge</span>
            </div>
            <div className="page-style-body margin">
              {_.map(MARGIN_LOCATIONS, (margin) => (
                <FormGroup key={margin.key} className="page-editor-dd" data-cy={`page-editor-${margin.title}`}>
                  <ControlLabel>{margin.title}</ControlLabel>
                  <Dropdown
                    id="margin-dropdown"
                    onSelect={(size) => this.saveLayoutStyle(LAYOUT_TYPES.PAGE_MARGIN, margin.key, size)}
                    title={`${pageMargin.inches[margin.key].toFixed(1)}"`}
                    block
                    size="small"
                  >
                    {_.map(MARGIN_SIZES, (size, idx) => (
                      <MenuItem key={idx} eventKey={size.value}>
                        {size.label}"
                      </MenuItem>
                    ))}
                  </Dropdown>
                </FormGroup>
              ))}
            </div>
          </div>

          <div className="page-style-container line-numbers" data-cy="page-style-line-numbers">
            <div className="page-style-title">
              <span>Page line numbers</span>
            </div>
            <div className="page-style-body line-number">
              <FormGroup key="line-numbers">
                <Dropdown
                  id="line-number-dropdown"
                  title={`${this.numberScheme.title}`}
                  block
                  size="small"
                  onSelect={(scheme) => this.saveNative('numberScheme', scheme)}
                >
                  {_.map(LINE_NUMBER_OPTIONS, (option, idx) => (
                    <MenuItem key={idx} eventKey={option.key}>
                      {option.title}
                    </MenuItem>
                  ))}
                </Dropdown>
              </FormGroup>
            </div>
          </div>

          {_.map(BORDER_LOCATIONS, (border) => {
            const borderStyle = this.getBorderStyle(border.key);
            const layoutValue = borderStyle.layout.inches[borderStyle.layoutKey].toFixed(1);
            return (
              <div className="page-style-container borders" data-cy="page-style-container">
                <div className="page-style-title" data-cy="page-style-title">
                  <span>{border.title}</span>
                  <br />
                  {border.align === 'vertical' && <span className="description">Used for pleadings</span>}
                </div>
                <div className="page-style-body enabled border" data-cy="border-style-body">
                  <FormGroup key={border.key}>
                    <Switch
                      checked={borderStyle.enabled}
                      id="border"
                      onChange={(value) => this.saveBorderStyle(border.key, 'enabled', value)}
                      size="small"
                    >
                      Enable
                    </Switch>
                  </FormGroup>
                  {borderStyle.enabled && (
                    <div className="border-editor" data-cy="border-editor">
                      {_.map(BORDER_EDITABLE_PROPERTIES, (property) => (
                        <FormGroup key={property.key} className="page-editor-dd">
                          <ControlLabel>
                            {property.key === 'layout'
                              ? property.title.slice(0, 5) + border.layoutKey + property.title.slice(5)
                              : property.title}
                          </ControlLabel>
                          <Dropdown
                            id={`${property.key}-dropdown`}
                            block
                            size="small"
                            title={
                              property.key === 'layout'
                                ? `${layoutValue}${property.units}`
                                : `${borderStyle[property.key]}${property.units}`
                            }
                            onSelect={(prop) => this.saveBorderStyle(border.key, property.key, prop)}
                          >
                            {_.map(property.values, (prop, idx) => (
                              <MenuItem key={idx} eventKey={prop.value}>
                                {prop.label}
                                {property.units}
                              </MenuItem>
                            ))}
                          </Dropdown>
                        </FormGroup>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Modal.Body>
    );
  }

  renderGeneral() {
    const { theme } = this.props;
    const { editingStyle, selectedType, native } = this.state;

    if (!theme || !editingStyle) return null;

    const typeStyle = this.typeStyle;

    return (
      <Modal.Body>
        <div className="style-list" data-cy="style-list">
          <ul>
            {_.map(REQUIRED_TYPE, (ts, idx) => (
              <TooltipButton key={idx} placement="right" tip={ts.description}>
                <li className={cx('type-style', { active: selectedType === ts })} onClick={() => this.selectType(ts)}>
                  {ts.title}
                </li>
              </TooltipButton>
            ))}
          </ul>
        </div>
        <div className={cx('ds-editor', { simple: native }, { advanced: !native })}>
          {native && (
            <Form>
              <div className="control-row">
                <FormGroup className="font">
                  <ControlLabel>Font</ControlLabel>
                  <Dropdown
                    id="dse-dd-font"
                    onSelect={(font) => this.saveNative('font', font)}
                    title={typeStyle.selectedFont.displayName}
                    block
                    dataCyToggle="dse-dd-font"
                  >
                    {_.map(THEME_FONTS, (font, key) => (
                      <MenuItem key={key} eventKey={font.key} data-cy="font-option">
                        {font.displayName}
                      </MenuItem>
                    ))}
                  </Dropdown>
                </FormGroup>
              </div>

              <div className="control-row">
                <FormGroup className="size">
                  <ControlLabel>Size</ControlLabel>
                  <Dropdown
                    id="dse-dd-size"
                    onSelect={(size) => this.saveNative('size', size)}
                    title={`${typeStyle.selectedSize.label} pt`}
                    block
                    dataCyToggle="dse-dd-size"
                  >
                    {_.map(FONT_SIZES, (size, idx) => (
                      <MenuItem key={idx} eventKey={size.value} data-cy="size-option">
                        {size.label} pt
                      </MenuItem>
                    ))}
                  </Dropdown>
                </FormGroup>

                <FormGroup className="line-spacing">
                  <ControlLabel>Line Spacing</ControlLabel>
                  <Dropdown
                    id="dse-dd-ls"
                    onSelect={(lineSpacing) => this.saveNative('lineSpacing', lineSpacing)}
                    title={typeStyle.selectedLineSpacing.label}
                    block
                    dataCyToggle="dse-dd-ls"
                  >
                    {_.map(LINE_SPACINGS, (sp, idx) => (
                      <MenuItem key={idx} eventKey={sp.value} data-cy="ls-option">
                        {sp.label}
                      </MenuItem>
                    ))}
                  </Dropdown>
                </FormGroup>

                <FormGroup className="formatting" data-cy="formatting">
                  <ControlLabel>Formatting</ControlLabel>
                  <ButtonGroup>
                    {INLINE_STYLES.map((type) => {
                      const active = _.get(editingStyle, `native.${type.command}`, false);
                      return (
                        <TooltipButton key={type.label} tip={`Toggle ${type.command} on selected text`} placement="top">
                          <Button
                            icon={type.command}
                            onClick={() => this.saveNative(type.command, !active)}
                            className={cx({ active })}
                          />
                        </TooltipButton>
                      );
                    })}
                  </ButtonGroup>
                </FormGroup>
              </div>

              <div className="control-row">
                <FormGroup className="color">
                  <ControlLabel>Color</ControlLabel>
                  <ColorPicker
                    style="compact"
                    color={_.get(editingStyle, 'native.color', 'black')}
                    onChange={(color) => this.saveNative('color', color)}
                  />
                </FormGroup>

                <FormGroup className="alignment" data-cy="alignment">
                  <ControlLabel>Alignment</ControlLabel>
                  <ButtonGroup>
                    {ALIGNMENT_STYLES.map((align) => {
                      const active = _.get(editingStyle, `native.alignment`, 'left') === align.value;
                      const disabled = typeStyle.name === 'LineNumbering' ? align.value !== 'right' : false;
                      return (
                        <TooltipButton key={align.label} tip={`${align.label}-align this style`} placement="top">
                          <Button
                            icon={align.icon}
                            onClick={() => this.saveNative('alignment', align.value)}
                            className={cx({ active })}
                            disabled={disabled}
                          />
                        </TooltipButton>
                      );
                    })}
                  </ButtonGroup>
                </FormGroup>
              </div>
            </Form>
          )}
          {!native && (
            <RJV
              theme="ocean"
              name={selectedType.key}
              displayObjectSize={false}
              displayDataTypes={false}
              enableClipboard={false}
              onEdit={this.onEdit}
              src={editingStyle}
            />
          )}
          {typeStyle.name !== 'LineNumbering' && (
            <div className="ds-preview" style={this.typeStyle.css} data-cy="ds-preview">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat.
            </div>
          )}
          {typeStyle.name === 'LineNumbering' && (
            <div className="ds-preview line-numbering" data-cy="ds-preview">
              {[...Array(10).keys()].slice(1).map((num, idx) => (
                <p style={this.typeStyle.css}>{num}</p>
              ))}
            </div>
          )}
        </div>
      </Modal.Body>
    );
  }
}
