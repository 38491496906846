/* eslint-disable react/no-find-dom-node */
import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import DealRole from '@core/enums/DealRole';
import { REALTIME_EVENTS } from '@core/models/DealRecord';
import { ARCHIVED_TAG } from '@core/models/TagStore';
import { FEATURES } from '@core/models/User';
import { dt, dts } from '@core/utils';

import { Dropdown, MenuItem } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';
import Dealer, { Category } from '@root/Dealer';
import Fire from '@root/Fire';

import DuplicateDealCheck from '../DuplicateDealCheck';
import LeaveDeal from './LeaveDeal';

@autoBindMethods
export default class BatchActionDropdown extends Component {
  static propTypes = {
    deals: PropTypes.array.isRequired,
    confirmDelete: PropTypes.func.isRequired,
    manageUsers: PropTypes.func.isRequired,
    onSign: PropTypes.func.isRequired,
    realtimeUpdate: PropTypes.func.isRequired,
    confirmExport: PropTypes.func.isRequired,
    onActionComplete: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      showLeave: false,
      showDuplicate: false,
    };
  }

  // See if user is owner, admin or observer owner of ALL selected deals
  get isOwner() {
    const { deals: dealRecords, user } = this.props;

    const observerOwnerTeams = _.filter(_.keys(user.teams), (team) => {
      const { teamMemberships } = user;
      return teamMemberships?.[team]?.observerRole === 'owner';
    });

    const ownerDeals = _.filter(dealRecords, (deal) => {
      return deal.userRole(user.id) === DealRole.OWNER || _.includes(observerOwnerTeams, deal.sourceTeam);
    });

    return ownerDeals.length === dealRecords.length;
  }

  // See if user is owner of ALL selected deals to manage deal user
  get isManageUser() {
    const { deals: dealRecords, user } = this.props;
    const roles = _.filter(dealRecords, (deal) => deal.userRole(user.id) === DealRole.OWNER);
    return roles.length === dealRecords.length;
  }

  // See if user can leave ALL selected deals
  get canLeave() {
    const { deals: dealRecords, user } = this.props;
    const leavable = _.filter(dealRecords, (deal) => deal.canLeave(user.id));
    return leavable.length === dealRecords.length;
  }

  // In order to attempt signing in bulk,
  // User must be signer on all deals, and all deals must be from the same template
  get canSign() {
    const { deals: dealRecords, user } = this.props;
    const signable = _.filter(dealRecords, (deal) => deal.canTrySigning(user.id));
    const templates = _.uniq(_.map(dealRecords, 'sourceTemplateKey'));

    // return templates.length === 1 && signable.length === dealRecords.length;
    return signable.length === dealRecords.length;
  }

  // See if user has tag on ALL selected deals
  hasTag(tagID) {
    const { deals } = this.props;
    const tagged = _.filter(deals, (deal) => deal.tags.indexOf(tagID) > -1);
    return tagged.length === deals.length;
  }

  async handleAction(action) {
    const { confirmDelete, manageUsers, deals, confirmExport, onActionComplete, onSign } = this.props;

    switch (action) {
      case 'archive':
        await this.toggleArchivedTag(true);
        onActionComplete(action);
        break;
      case 'unarchive':
        await this.toggleArchivedTag(false);
        onActionComplete(action);
        break;
      case 'leave':
        this.setState({ showLeave: true });
        break;
      case 'delete':
        confirmDelete(deals);
        break;
      case 'users':
        manageUsers(deals);
        break;
      case 'sign':
        onSign(deals);
        break;
      case 'export':
        confirmExport(deals);
        break;
      case 'duplicate-check':
        this.setState({ showDuplicate: true });
        break;
      default:
        break;
    }
  }

  async leaveDeals() {
    const { deals, user, realtimeUpdate, onActionComplete } = this.props;
    for (let i = 0; i < deals.length; i++) {
      const deal = deals[i];
      Dealer.call({ category: Category.DEAL, action: 'leave' });
      await Fire.leaveDeal(user.id, deal.dealID);
      await realtimeUpdate(deal, 'leave');
    }
    onActionComplete('leave');
  }

  async toggleArchivedTag(archive) {
    const { deals, user } = this.props;
    const tag = ARCHIVED_TAG.tagID;
    let realtimeEvent;
    for (let i = 0; i < deals.length; i++) {
      const deal = deals[i];
      realtimeEvent = archive ? REALTIME_EVENTS.TAG : REALTIME_EVENTS.UNTAG;

      const idx = deal.tags.indexOf(tag);
      if (archive && idx === -1) {
        deal.tags.push(tag);
      } else if (!archive && idx > -1) {
        deal.tags.splice(idx, 1);
      }
      // This shouldn't be possible but if we're either adding a tag that was already there,
      // or removing a tag that wasn't there to begin with... do nothing :-)
      else {
        // console.log(`Deal [${deal.dealID}] already ${idx > -1 ? 'tagged' : 'untagged' } -- skipping`);
        continue;
      }

      Dealer.call({ category: Category.DEAL, action: `${realtimeEvent}:${tag}` });
      await Fire.updateDealUserTags(user.id, deal.dealID, deal.tags);
    }
    await this.onTagsUpdated(deals, tag, realtimeEvent);
  }

  async onTagsUpdated(deals, tag, realtimeEvent) {
    const { realtimeUpdate } = this.props;

    for (let i = 0; i < deals.length; i++) {
      const deal = deals[i];
      await realtimeUpdate(deal, realtimeEvent, tag);
    }
  }

  render() {
    const { deals, user } = this.props;
    const archived = this.hasTag(ARCHIVED_TAG.tagID);
    const { showLeave, showDuplicate } = this.state;

    // Note -- we're showing all menu options, with disabled tooltips for the ones that are in accessible
    // Disabling a TooltipButton causes it to not render (i.e., only render children),
    // so the disabled logic for the TooltipButtons is intentionally the opposite of their child MenuItems
    return (
      <>
        <Dropdown
          id="dd-batch"
          dmpStyle="link-primary"
          noPadding
          onSelect={this.handleAction}
          title={`${deals.length} ${deals.length > 1 ? dts : dt} selected`}
        >
          <MenuItem eventKey={archived ? 'unarchive' : 'archive'}>
            {`${archived ? 'Unarchive' : 'Archive'} ${deals.length > 1 ? dts : dt}`}
          </MenuItem>

          <MenuItem disabled={!this.canLeave} eventKey="leave">
            <TooltipButton
              disabled={this.canLeave}
              placement="right"
              tip={`You must add another owner to all selected ${deals.length > 1 ? dts : dt} in order to leave them`}
            >
              <span>Leave {deals.length > 1 ? dts : dt}</span>
            </TooltipButton>
          </MenuItem>

          {user.can(FEATURES.BULK_SIGN) && (
            <MenuItem disabled={!this.canSign} eventKey="sign">
              <TooltipButton
                disabled={this.canSign}
                placement="right"
                tip={`You must be a signer on all selected ${deals.length > 1 ? dts : dt} in order to sign in bulk`}
              >
                <span>Sign {deals.length > 1 ? dts : dt}</span>
              </TooltipButton>
            </MenuItem>
          )}

          <MenuItem disabled={!this.isManageUser} eventKey="users">
            <TooltipButton
              disabled={this.isManageUser}
              placement="right"
              tip={`You must be an owner of all selected ${deals.length > 1 ? dts : dt} in order to manage users`}
            >
              <span>Manage users</span>
            </TooltipButton>
          </MenuItem>

          <MenuItem className="text-danger" disabled={!this.isOwner} eventKey="delete">
            <TooltipButton
              disabled={this.isOwner}
              placement="right"
              tip={`You must be an owner of all selected ${deals.length > 1 ? dts : dt} in order to delete them`}
            >
              <span>Move to trash</span>
            </TooltipButton>
          </MenuItem>

          <MenuItem divider />

          <MenuItem eventKey="export">Export CSV</MenuItem>
          {user.isSuper && <MenuItem eventKey="duplicate-check">Duplicate Check</MenuItem>}
        </Dropdown>

        {showLeave && (
          <LeaveDeal
            onHide={() => {
              this.setState({ showLeave: false });
            }}
            onConfirm={this.leaveDeals}
          />
        )}

        {showDuplicate && (
          <DuplicateDealCheck
            deals={deals}
            show={showDuplicate}
            onHide={() => {
              this.setState({ showDuplicate: false });
            }}
          />
        )}
      </>
    );
  }
}
