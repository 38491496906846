import CryptoJS from 'crypto-js';
import { compact, forEach } from 'lodash';

export const getUrlFromInvite = (invite, baseUrl = '') => {
  return `${baseUrl}/invitation/${invite.inviteID}/${invite.to.key}`;
};

export const randomKey = () => Math.floor(Math.random() * (1000000 - 10000)) + 10000;

export const getUniqueKey = () =>
  CryptoJS.MD5(new Date().toString() + randomKey())
    .toString()
    .substring(0, 12);

export const getSafeKey = (source = '', unique = false, keepCase = false, replace = '-') => {
  let key = source.replace(/[^-\w\d]/g, replace);
  if (!keepCase) key = key.toLowerCase();
  if (unique) key += `-${getUniqueKey()}`;
  return key;
};

export const getBaseUrl = () => `${window.location.protocol}//${window.location.host}`;

export const classNamePrefixer = (suffix, prefix = 'dmp') => {
  return (componentSuffix) => compact([prefix, suffix, componentSuffix]).join('-');
};

export const getDealUrl = (deal) => {
  return `${getBaseUrl()}/deals/${deal.dealID}`;
};

// https://stackoverflow.com/questions/25354313/saving-a-uint8array-to-a-binary-file
export const getDataURL = (data) => {
  if (data instanceof ArrayBuffer) {
    data = new Uint8Array(data);
  }
  const blob = new Blob([data], { type: 'application/octet-stream' });
  return window.URL.createObjectURL(blob);
};

export const arrayToBase64 = (data) => {
  let s = '';
  for (let i = 0; i < data.length; i++) s += String.fromCharCode(data[i]);
  return window.btoa(s);
};

// https://stackoverflow.com/questions/21797299/convert-base64-string-to-arraybuffer/21797381
export const base64ToArray = (data) => {
  const byteString = atob(data);
  const array = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    array[i] = byteString.charCodeAt(i);
  }
  return array;
};

// This is used to create a valid API get call (with token embedded),
// To generate and fetch an exported version of a deal (pdf/docx/json) or AuditLog
export const generateExportURL = ({ deal, token, type = 'pdf', options = {} }) => {
  const loc = window.location;
  let url = `${loc.protocol}//${loc.hostname}${loc.port ? ':' + loc.port : ''}/api/${type}`;
  url += `?dealID=${deal.dealID}`;
  url += `&token=${token}`;
  forEach(options, (val, key) => (url += `&${key}=${val}`));

  //https://stackoverflow.com/questions/9772955/how-can-i-get-the-timezone-name-in-javascript
  const intl = Intl.DateTimeFormat().resolvedOptions();
  const {locale, timeZone} = intl;
  url += `&locale=${locale}&timeZone=${encodeURIComponent(timeZone)}`;
  return url;
};

export const generateNumberOptions = (fromNum, toNum, increment, decimals) => {
  const options = [];
  for (let i = fromNum; i <= toNum; i += increment) {
    const val = i.toFixed(decimals);
    options.push({
      label: val,
      value: parseFloat(val),
    });
  }
  return options;
};
