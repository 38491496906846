import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonToolbar, Dropdown, MenuItem } from '@components/dmp';

import Auth from '@root/Auth';

export const ACCOUNT_ITEMS = [
  { path: '/account/profile', title: 'Profile' },
  /* {path: '/account/billing', title: 'Billing'}, */
  { path: () => Auth.logout(), title: 'Logout' },
];

const UserMenu = (props) => {
  const { user, history, location } = props;
  const isSignedIn = !_.get(user, 'anonymous', true);
  const className = 'user-menu';

  if (!isSignedIn) {
    // Capture current path so that user will land back where they came from after login/signup
    const returnPath = encodeURIComponent(location.pathname);
    return (
      <ButtonToolbar align="right" className={`${className} anonymous`}>
        <a onClick={() => history.push(`/signup/?redirect=${returnPath}`)}>Sign Up</a>
        {' / '}
        <a onClick={() => history.push(`/login/?redirect=${returnPath}`)}>Log in</a>
      </ButtonToolbar>
    );
  }

  return (
    <ButtonToolbar align="right" className={className}>
      <Dropdown dmpStyle="link" title={user.email} pullRight id="login-dropdown" size="small" noUnderline noPadding>
        {ACCOUNT_ITEMS.map((item, idx) => (
          <MenuItem key={idx} onClick={() => (typeof item.path == 'function' ? item.path() : history.push(item.path))}>
            {item.title}
          </MenuItem>
        ))}
      </Dropdown>
    </ButtonToolbar>
  );
};

UserMenu.defaultProps = {
  user: null,
};

UserMenu.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object,
};

export default UserMenu;
